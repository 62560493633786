import { IStrategy } from '@navix/utils/adapter'

import { Vendor, VendorStatuses } from '../domain/vendor.model'
import { GetVendorDetailsResponse } from '../domain/get-vendor-details.response'

export class FromGetVendorDetailsResponse
  implements IStrategy<GetVendorDetailsResponse, Vendor>
{
  convert(response: GetVendorDetailsResponse): Vendor {
    return {
      name: response.description,
      status: response.vendorStatusTypeDescription as VendorStatuses,
      statusId: response.vendorStatusTypeId,
      dotNumber: response.dotNumber,
      mcNumber: response.mcNumber,
      scac: response.scacCode,
      paymentMethod: response.paymentMethod,
      accountNumbers: <Vendor['accountNumbers']>response.accountNumbers.map(
        x => ({
          accountNumber: x.accountNumber,
          isPrimary: x.isPrimary,
          id: x.vendorAccountNumberId
        })
      ),
      withDetails: true,
      currencyId: response.currencyId,
      currencyDescription: response.currencyDescription,
      billingTermId: response.billingTermId,
      billingTermDescription: response.billingTermDescription,
      externalId: response.externalId
    } as Vendor
  }
}
