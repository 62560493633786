import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'

import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import {
  API_URL,
  API_URL_V1_1_VERSION,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { AddVendorAddressRequest } from '../domain/add-vendor-address.request'
import { AddVendorContactRequest } from '../domain/add-vendor-contact.request'
import { AddVendorRequest } from '../domain/add-vendor.request'
import { DataFilters, StatusFilter } from '../domain/data-filters.model'
import { GetVendorAddressTypesResponse } from '../domain/get-vendor-address-types.response'
import { GetVendorAddressesResponse } from '../domain/get-vendor-addresses.response'
import { GetVendorContactsResponse } from '../domain/get-vendor-contacts.response'
import { GetVendorDetailsResponse } from '../domain/get-vendor-details.response'
import { GetVendorStatusesResponse } from '../domain/get-vendor-statuses.response'
import { GetVendorsResponse } from '../domain/get-vendors.response'
import { UpdateVendorAddressRequest } from '../domain/update-vendor-address.request'
import { UpdateVendorContactRequest } from '../domain/update-vendor-contact.request'
import { UpdateVendorRequest } from '../domain/update-vendor.request'

@Injectable({
  providedIn: 'root'
})
export class VendorsService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiV1_1Url = inject(API_URL_V1_1_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly parser = inject<OdataParser>(QUERY_PARSER)

  private readonly apiVendorsUrl = `${this.apiUrl}vendors/` as const
  private readonly apiVendorsNewUrl = `${this.apiV1_1Url}vendors/` as const
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  private filterColumns = [
    'Description',
    'DotNumber',
    'McNumber',
    'ScacCode',
    'Address1',
    'City',
    'State',
    'Zip'
  ]

  getVendors(filters: DataFilters): Observable<GetVendorsResponse> {
    const query = this.parser
      .prepare('vendor')
      .setCount(true)
      .setContainFilterColumns(filters.filterColumns ?? this.filterColumns)

    if (filters.search) query.setContainFilter(filters.search)
    if (filters.itemsPerPage) query.setTop(filters.itemsPerPage)
    if (filters.itemsPerPage && filters.page)
      query.setSkip(filters.itemsPerPage * (filters.page - 1))
    if (filters.status === StatusFilter.active)
      query.setEqualityFilter('StatusDescription', 'Active')
    if (filters.status === StatusFilter.inactive)
      query.setEqualityFilter('StatusDescription', 'Inactive')
    if (filters.sortBy && filters.sortDirection)
      query.setOrderBy({
        column: filters.sortBy,
        direction: filters.sortDirection
      })
    if (filters.uuids && filters.uuids.length)
      query.setInPropertyFilterColumns('Uuid', filters.uuids)
    if (filters.ids && filters.ids.length)
      query.setInPropertyFilterColumns('VendorId', filters.ids)
    if (filters.select && filters.select.length) query.setSelect(filters.select)

    return this._http.get<GetVendorsResponse>(`${this.apiVendorsNewUrl}list`, {
      params: query.getQueryParamsAsHttpParams(),
      headers: this.headersWithApiKey
    })
  }

  getVendorStatuses(): Observable<GetVendorStatusesResponse> {
    return this._http.get<GetVendorStatusesResponse>(
      `${this.apiVendorsUrl}vendor-status-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
  getVendorAddressTypes(): Observable<GetVendorAddressTypesResponse> {
    return this._http.get<GetVendorAddressTypesResponse>(
      `${this.apiVendorsUrl}vendor-location-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addVendor(request: AddVendorRequest): Observable<void> {
    return this._http.post<void>(`${this.apiVendorsNewUrl}vendors`, request, {
      headers: this.headersWithApiKey
    })
  }

  getVendorDetails(vendorId: number): Observable<GetVendorDetailsResponse> {
    return this._http.get<GetVendorDetailsResponse>(
      `${this.apiVendorsNewUrl}${vendorId}/detail`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getVendorContacts(
    vendorId: number,
    filters?: DataFilters
  ): Observable<GetVendorContactsResponse> {
    const query = this.parser.prepare('VendorContacts').setCount(true)

    if (filters?.itemsPerPage) query.setTop(filters?.itemsPerPage)
    if (filters?.itemsPerPage && filters?.page)
      query.setSkip(filters?.itemsPerPage * (filters?.page - 1))

    if (filters?.sortBy && filters.sortDirection) {
      const persistentSort = {
        column: 'isPrimary' as const,
        direction: 'desc' as const
      }
      const filterSortBy = {
        column: filters.sortBy as string,
        direction: filters.sortDirection
      }
      query.setMultipleOrderBy(
        persistentSort.column === filterSortBy.column
          ? [filterSortBy]
          : [persistentSort, filterSortBy]
      )
    }
    return this._http.get<GetVendorContactsResponse>(
      `${this.apiVendorsNewUrl}${vendorId}/vendor-contacts`,
      {
        params: query.getQueryParamsAsHttpParams(),
        headers: this.headersWithApiKey
      }
    )
  }

  getVendorAddresses(
    vendorId: number,
    filters: DataFilters
  ): Observable<GetVendorAddressesResponse> {
    const query = this.parser.prepare('VendorLocations').setCount(true)

    if (filters?.itemsPerPage) query.setTop(filters?.itemsPerPage)
    if (filters?.itemsPerPage && filters?.page)
      query.setSkip(filters?.itemsPerPage * (filters?.page - 1))

    if (filters?.sortBy && filters.sortDirection) {
      const persistentSort = {
        column: 'isPrimary' as const,
        direction: 'desc' as const
      }
      const filterSortBy = {
        column: filters.sortBy as string,
        direction: filters.sortDirection
      }
      query.setMultipleOrderBy(
        persistentSort.column === filterSortBy.column
          ? [filterSortBy]
          : [persistentSort, filterSortBy]
      )
    }

    if (filters?.isActive) {
      query.setEqualityFilter('IsActive', filters.isActive)
    }

    return this._http.get<GetVendorAddressesResponse>(
      `${this.apiVendorsNewUrl}${vendorId}/vendor-locations`,
      {
        params: query.getQueryParamsAsHttpParams(),
        headers: this.headersWithApiKey
      }
    )
  }

  updateVendor(request: UpdateVendorRequest) {
    return this._http.put<void>(`${this.apiVendorsNewUrl}vendors`, request, {
      headers: this.headersWithApiKey
    })
  }

  addVendorContact(request: AddVendorContactRequest) {
    return this._http.post<void>(
      `${this.apiVendorsNewUrl}vendor-contacts`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addVendorAddress(request: AddVendorAddressRequest) {
    return this._http.post<void>(
      `${this.apiVendorsNewUrl}vendor-locations`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateVendorAddress(request: UpdateVendorAddressRequest) {
    return this._http.put<void>(
      `${this.apiVendorsNewUrl}vendor-locations`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateVendorContact(request: UpdateVendorContactRequest) {
    return this._http.put<void>(
      `${this.apiVendorsNewUrl}vendor-contacts`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteVendorContact(vendorContactId: number) {
    return this._http.delete<void>(
      `${this.apiVendorsNewUrl}vendor-contacts/${vendorContactId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateVendorAddressActiveStatus(addressId: number, active: boolean) {
    return this._http.post<void>(
      `${this.apiVendorsNewUrl}${addressId}/activation`,
      active,
      {
        headers: this.headersWithApiKey
      }
    )
  }
}
