import { IStrategy } from '@navix/utils/adapter'

import { VendorForm } from '../domain/vendor-form.model'
import { UpdateVendorAddressRequest } from '../domain/update-vendor-address.request'

export class ToUpdateVendorAddressRequest
  implements IStrategy<VendorForm, UpdateVendorAddressRequest>
{
  convert(vendor: VendorForm): UpdateVendorAddressRequest {
    return {
      address1: vendor.address.address1,
      address2: vendor.address.address2,
      addressName: vendor.address.name,
      city: vendor.address.city,
      country: vendor.address.countryCode as string,
      isPrimary: vendor.address.isPrimary ?? false,
      state: vendor.address.stateCode as string,
      zip: vendor.address.zip,
      locationTypeId: vendor.address.typeId?.toString() ?? '',
      vendorId: vendor.id,
      vendorLocationTypeId: vendor.address.typeId?.toString() ?? '',
      vendorLocationId: vendor.address.id as number
    }
  }
}
