import { IStrategy } from '@navix/utils/adapter'
import { AddVendorRequest } from '../domain/add-vendor.request'
import { VendorForm } from '../domain/vendor-form.model'

export class ToAddVendorRequest
  implements IStrategy<VendorForm, AddVendorRequest>
{
  convert(vendor: VendorForm): AddVendorRequest {
    const request: AddVendorRequest = {
      description: vendor.details.name,
      dotNumber:
        vendor.details.dotNumber.trim() === ''
          ? undefined
          : vendor.details.dotNumber,
      mcNumber: vendor.details.mcNumber,
      scacCode: vendor.details.scac,
      addressName: vendor.address.name,
      address1: vendor.address.address1,
      address2: vendor.address.address2,
      city: vendor.address.city,
      state: vendor.address.stateCode as string,
      zip: vendor.address.zip,
      country: vendor.address.countryCode as string,
      principalContact: {
        contactName: vendor.contact.name,
        department: vendor.contact.department,
        contactPhone: vendor.contact.phone,
        contactEmail: vendor.contact.email
      },
      vendorStatusTypeId: vendor.details.statusId,
      currencyId: vendor.details.currencyId as number,
      billingTermId: vendor.details.billingTermId,
      externalId: vendor.details.externalId
    }

    if (
      Object.values(request.principalContact ?? {}).every(
        v => v === '' || v === undefined || v === null
      )
    )
      delete request.principalContact

    return request
  }
}
