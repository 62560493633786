import { makeEnvironmentProviders } from '@angular/core'
import { provideEffects } from '@ngrx/effects'
import { VendorsEffects } from '../+state/vendors.effects'
import { VENDORS_FEATURE_KEY, vendorsReducer } from '../+state/vendors.reducer'
import { provideState } from '@ngrx/store'
import { VendorsFacade } from '../+state/vendors.facade'

export function provideVendorsFeature() {
  return makeEnvironmentProviders([
    provideState(VENDORS_FEATURE_KEY, vendorsReducer),
    provideEffects(VendorsEffects),
    VendorsFacade
  ])
}
