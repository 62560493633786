import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'

import { vendorsActions } from './vendors.actions'
import { Vendor } from '../domain/vendor.model'
import { VendorsLoading } from '../domain/vendors-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { StatusType } from '../domain/status-type.model'
import { AccountNumberStatus } from '../domain/account-number.model'
import { AddressType } from '../domain/address-type.model'

export const VENDORS_FEATURE_KEY = 'feature-vendors'

export interface VendorsState extends EntityState<Vendor> {
  selectedId?: string | number
  totalCount: number
  loading: VendorsLoading
  filters: DataFilters
  contactFilters: DataFilters
  addressesFilters: DataFilters
  statusTypes: StatusType[]
  addressTypes: AddressType[]
  accountNumberStatuses: AccountNumberStatus[]
}

export interface VendorsPartialState {
  readonly [VENDORS_FEATURE_KEY]: VendorsState
}

export const vendorsAdapter: EntityAdapter<Vendor> =
  createEntityAdapter<Vendor>()

export const initialVendorsState: VendorsState = vendorsAdapter.getInitialState(
  {
    totalCount: 0,
    loading: {
      getAll: false,
      add: false,
      getDetails: false,
      getContacts: false,
      getAddresses: false,
      updateVendor: false,
      addContact: false,
      addAddress: false,
      updateVendorAddress: false,
      updateVendorContact: false,
      deleteVendorContact: false,
      updateVendorAccountNumbers: false
    },
    filters: {
      search: undefined,
      page: 1,
      itemsPerPage: 25,
      sortBy: 'Description',
      sortDirection: 'asc'
    },
    contactFilters: {
      page: 1,
      itemsPerPage: 10,
      sortBy: 'contactName',
      sortDirection: 'asc'
    },
    addressesFilters: {
      page: 1,
      itemsPerPage: 10,
      sortBy: 'isPrimary',
      sortDirection: 'desc',
      isActive: false
    },
    statusTypes: [],
    addressTypes: [],
    accountNumberStatuses: [
      { isPrimary: true, description: 'Primary' },
      { isPrimary: false, description: 'Secondary' }
    ]
  }
)

const reducer = createReducer(
  initialVendorsState,
  on(
    vendorsActions.loadVendorsSuccess,
    (state, { vendors }): VendorsState => vendorsAdapter.setAll(vendors, state)
  ),
  on(
    vendorsActions.loadVendorDetails,
    (state, { vendorId }): VendorsState => ({ ...state, selectedId: vendorId })
  ),
  on(
    vendorsActions.loadVendorDetailsSuccess,
    (state, { vendor }): VendorsState => vendorsAdapter.upsertOne(vendor, state)
  ),
  on(
    vendorsActions.loadVendorContactsSuccess,
    (state, { vendorWithContacts }): VendorsState =>
      vendorsAdapter.upsertOne(vendorWithContacts, state)
  ),
  on(
    vendorsActions.loadVendorAddressesSuccess,
    (state, { vendorWithAddresses }): VendorsState =>
      vendorsAdapter.upsertOne(vendorWithAddresses, state)
  ),
  on(
    vendorsActions.setTotalCount,
    (state, { count }): VendorsState => ({
      ...state,
      totalCount: count
    })
  ),
  on(
    vendorsActions.setLoading,
    (state, { operation, loading }): VendorsState => ({
      ...state,
      loading: { ...state.loading, [operation]: loading }
    })
  ),
  on(
    vendorsActions.resetListFilters,
    (state): VendorsState => ({
      ...state,
      filters: initialVendorsState.filters
    })
  ),
  on(
    vendorsActions.setContactListFilters,
    (state, { contactFilters }): VendorsState => ({
      ...state,
      contactFilters
    })
  ),
  on(
    vendorsActions.setAddessesListFilters,
    (state, { addressesFilters }): VendorsState => ({
      ...state,
      addressesFilters
    })
  ),
  on(
    vendorsActions.setListFilters,
    (state, { filters }): VendorsState => ({
      ...state,
      filters
    })
  ),
  on(
    vendorsActions.loadVendorsStatusesSuccess,
    (state, { statusTypes }): VendorsState => ({
      ...state,
      statusTypes
    })
  ),
  on(
    vendorsActions.loadVendorAddressTypesSuccess,
    (state, { addressTypes }): VendorsState => ({
      ...state,
      addressTypes
    })
  ),
  on(
    vendorsActions.updateVendorAddressActiveStatusSuccess,
    (state, { vendorWithUpdatedAddress }): VendorsState =>
      vendorsAdapter.upsertOne(vendorWithUpdatedAddress as Vendor, state)
  ),
  on(
    vendorsActions.resetAddessesListFilters,
    (state): VendorsState => ({
      ...state,
      addressesFilters: initialVendorsState.addressesFilters
    })
  ),
  on(
    vendorsActions.resetContactListFilters,
    (state): VendorsState => ({
      ...state,
      contactFilters: initialVendorsState.contactFilters
    })
  ),
  on(
    vendorsActions.patchListFilters,
    (state, { filters }): VendorsState => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters
      }
    })
  )
)

export function vendorsReducer(
  state: VendorsState | undefined,
  action: Action
) {
  return reducer(state, action)
}
