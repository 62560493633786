import { IStrategy } from '@navix/utils/adapter'
import { UpdateVendorContactRequest } from '../domain/update-vendor-contact.request'
import { VendorForm } from '../domain/vendor-form.model'

export class ToUpdateVendorContactRequest
  implements IStrategy<VendorForm, UpdateVendorContactRequest>
{
  convert(vendor: VendorForm): UpdateVendorContactRequest {
    return {
      contactName: vendor.contact.name,
      department: vendor.contact.department,
      contactPhone: vendor.contact.phone,
      contactEmail: vendor.contact.email,
      isPrimary: vendor.contact.isPrimary ?? false,
      vendorId: vendor.id,
      vendorContactId: vendor.contact.id as number
    }
  }
}
