export type DataFilters = Partial<{
  search: string
  itemsPerPage: number
  page: number
  status: StatusFilter
  sortBy: string
  sortDirection: 'asc' | 'desc'
  isActive: boolean
  uuids: string[]
  ids: number[]
  select: string[]
  filterColumns: string[]
}>

export enum StatusFilter {
  all,
  active,
  inactive
}
