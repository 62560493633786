import { IStrategy } from '@navix/utils/adapter'
import { GetVendorsResponse } from '../domain/get-vendors.response'
import { Vendor, VendorStatuses } from '../domain/vendor.model'

const vendorStatusMap: Record<string, VendorStatuses> = {
  Active: VendorStatuses.Active,
  'Awaiting Approval': VendorStatuses.AwaitingApproval,
  Inactive: VendorStatuses.Inactive
}

export class FromGetVendorsResponse
  implements IStrategy<GetVendorsResponse, Vendor[]>
{
  convert(response: GetVendorsResponse): Vendor[] {
    const vendors = response.value.map(vendor => {
      const hasDefaultContact = !!vendor.ContactName && !!vendor.ContactEmail
      const defaultContact: Vendor['contacts'][number] = {
        email: vendor.ContactEmail,
        isPrimary: true,
        name: vendor.ContactName
      }
      return <Vendor>{
        id: vendor.VendorId,
        uuid: vendor.Uuid,
        withDetails: false,
        name: vendor.Description,
        status: getStatus(vendor.StatusDescription),
        dotNumber: vendor.DotNumber,
        mcNumber: vendor.McNumber,
        scac: vendor.ScacCode,
        externalId: vendor.ExternalId,
        address: {
          address1: vendor.Address1,
          address2: vendor.Address2,
          country: vendor.Country,
          city: vendor.City,
          state: vendor.State,
          zip: vendor.Zip,
          zip4: vendor.Zip4
        },
        contacts: hasDefaultContact ? [defaultContact] : []
      }
    })
    return vendors
  }
}

const getStatus = (statusDescription: string | null) =>
  statusDescription ? vendorStatusMap[statusDescription] : 'N/A'
