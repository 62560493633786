import { Injectable, inject } from '@angular/core'
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import { VendorForm, VendorFormControls } from '../domain/vendor-form.model'
const DEFAULT_STATUS_AWAITING_APPROVAL_ID = 3 as const

@Injectable({ providedIn: 'root' })
export class VendorFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getVendorForm() {
    return this.fb.group<Omit<VendorFormControls, 'id'>>({
      details: this.getDetailsForm(),
      address: this.getHeadquartersForm(),
      accountNumbers: this.getAccountNumbersFormWithDefaultGroup(),
      contact: this.getContactForm()
    })
  }
  getAccountNumbersFormWithDefaultGroup(): VendorFormControls['accountNumbers'] {
    return this.fb.array<FormGroup>([this.getAccountNumberForm()])
  }
  getDetailsForm(): VendorFormControls['details'] {
    return this.fb.group({
      name: this.fb.control('', [Validators.required]),
      dotNumber: this.fb.control<string>('', Validators.minLength(7)),
      mcNumber: this.fb.control<string>(''),
      scac: this.fb.control<string>('', [Validators.maxLength(12)]),
      statusId: this.fb.control<number | undefined>(
        DEFAULT_STATUS_AWAITING_APPROVAL_ID
      ),
      currencyId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      billingTermId: this.fb.control<number | undefined>(undefined),
      externalId: this.fb.control('', Validators.required)
    })
  }

  getHeadquartersForm(): VendorFormControls['address'] {
    return this.fb.group({
      name: this.fb.control(''),
      address1: this.fb.control(''),
      address2: this.fb.control<string>(''),
      countryCode: this.fb.control<string | undefined>(undefined),
      city: this.fb.control(''),
      stateCode: this.fb.control<string | undefined>(undefined),
      zip: this.fb.control(''),
      isPrimary: this.fb.control<boolean | undefined>(undefined),
      typeId: this.fb.control<number | undefined>(undefined),
      id: this.fb.control<number | undefined>(undefined)
    })
  }

  getAddressForm(): VendorFormControls['address'] {
    return this.fb.group({
      name: this.fb.control('', Validators.required),
      address1: this.fb.control('', Validators.required),
      address2: this.fb.control<string>(''),
      countryCode: this.fb.control<string | undefined>(
        undefined,
        Validators.required
      ),
      city: this.fb.control('', Validators.required),
      stateCode: this.fb.control<string | undefined>(
        undefined,
        Validators.required
      ),
      zip: this.fb.control('', Validators.required),
      isPrimary: this.fb.control<boolean | undefined>(
        undefined,
        Validators.required
      ),
      typeId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      ),
      id: this.fb.control<number | undefined>(undefined)
    })
  }

  getContactForm(): VendorFormControls['contact'] {
    return this.fb.group({
      name: this.fb.control<string>(''),
      department: this.fb.control<string | undefined>(undefined),
      email: this.fb.control<string>(''),
      phone: this.fb.control<string>(''),
      isPrimary: this.fb.control<boolean>(false),
      id: this.fb.control<number | undefined>(undefined)
    })
  }

  getAccountNumberForm(
    accountNumber?: Partial<VendorForm['accountNumbers'][number]>
  ) {
    const isPrimary = accountNumber
      ? Boolean(accountNumber?.isPrimary)
      : undefined
    return this.fb.group({
      isPrimary: this.fb.control<boolean | undefined>(
        isPrimary,
        Validators.required
      ),
      id: accountNumber?.id ?? 0,
      name: this.fb.control(accountNumber?.name ?? '', Validators.required)
    })
  }
}
