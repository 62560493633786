import { IStrategy } from '@navix/utils/adapter'
import { Vendor } from '../domain/vendor.model'
import { GetVendorAddressesResponse } from '../domain/get-vendor-addresses.response'

export class FromGetVendorAddressResponse
  implements IStrategy<GetVendorAddressesResponse, Vendor>
{
  convert(response: GetVendorAddressesResponse): Vendor {
    return {
      addresses: response.value.map(
        address =>
          <Vendor['addresses'][number]>{
            name: address.AddressName,
            address1: address.Address1,
            address2: address.Address2,
            country: address.Country,
            city: address.City,
            state: address.State,
            zip: address.Zip,
            isPrimary: address.IsPrimary,
            isActive: address.IsActive,
            typeDescription: address.VendorLocationTypeDescription,
            typeId: address.VendorLocationTypeId,
            id: address.VendorLocationId
          }
      )
    } as Vendor
  }
}
