export const VENDORS_ABSOLUTE_ROUTE_PREFIX = '/tenant-admin/vendor/s' as const
export const ADD_VENDOR_DIALOG_ID = 'ADD_VENDOR_DIALOG_ID' as const
export const EDIT_VENDOR_DIALOG_ID = 'EDIT_VENDOR_DIALOG_ID' as const
export const ADD_VENDOR_ADDRESS_DIALOG_ID =
  'ADD_VENDOR_ADDRESS_DIALOG_ID' as const
export const ADD_VENDOR_CONTACT_DIALOG_ID =
  'ADD_VENDOR_CONTACT_DIALOG_ID' as const
export const EDIT_VENDOR_ADDRESS_DIALOG_ID =
  'EDIT_VENDOR_ADDRESS_DIALOG_ID' as const
export const EDIT_VENDOR_CONTACT_DIALOG_ID =
  'EDIT_VENDOR_CONTACT_DIALOG_ID' as const
export const DELETE_VENDOR_CONTACT_DIALOG_ID =
  'DELETE_VENDOR_CONTACT_DIALOG_ID' as const
export const EDIT_VENDOR_ACCOUNT_NUMBERS_DIALOG_ID =
  'EDIT_VENDOR_ACCOUNT_NUMBERS_DIALOG_ID' as const
