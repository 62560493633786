import { AdapterBase } from '@navix/utils/adapter'
import { Vendor } from '../domain/vendor.model'
import { VendorForm } from '../domain/vendor-form.model'

export class ToVendorAdapter extends AdapterBase<unknown, Vendor | Vendor[]> {}
export class FromVendorAdapter extends AdapterBase<
  Vendor | VendorForm,
  unknown
> {}

export class VendorAdapter extends AdapterBase<unknown, unknown> {}
