import { Injectable, inject } from '@angular/core'
import { select, Store } from '@ngrx/store'

import { vendorsActions } from './vendors.actions'
import * as VendorsSelectors from './vendors.selectors'

import { filter, firstValueFrom } from 'rxjs'
import { AsyncOperations } from '../domain/vendors-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { VendorForm } from '../domain/vendor-form.model'

@Injectable()
export class VendorsFacade {
  private readonly store: Store = inject(Store)

  allVendors$ = this.store.pipe(select(VendorsSelectors.selectAllVendors))
  allVendorsAsEntity$ = this.store.pipe(
    select(VendorsSelectors.selectAllVendorsAsEntity)
  )
  allVendorStatuses$ = this.store.pipe(
    select(VendorsSelectors.selecVendorStatuses),
    filter(statuses => statuses.length > 0)
  )

  allVendorAddressTypes$ = this.store.pipe(
    select(VendorsSelectors.selectAddressTypes),
    filter(types => types.length > 0)
  )

  selectedVendorId$ = this.store.pipe(select(VendorsSelectors.selectSelectedId))
  selectedVendor$ = this.store.pipe(select(VendorsSelectors.selectEntity))
  totalCount$ = this.store.pipe(select(VendorsSelectors.selectTotalCount))
  accountNumberStatuses$ = this.store.pipe(
    select(VendorsSelectors.selectAccountNumberStatuses)
  )

  loading$ = this.store.pipe(select(VendorsSelectors.selectLoading))
  loading = this.store.selectSignal(VendorsSelectors.selectLoading)

  filters$ = this.store.pipe(select(VendorsSelectors.selecFilters))
  contactFilters$ = this.store.pipe(
    select(VendorsSelectors.selectContactListFilters)
  )
  addressFilters$ = this.store.pipe(
    select(VendorsSelectors.selectAddressesListFilters)
  )

  loadVendors(filters: DataFilters) {
    this.store.dispatch(vendorsActions.loadVendors({ filters }))
  }

  loadVendorsStatuses() {
    this.store.dispatch(vendorsActions.loadVendorsStatuses())
  }

  loadVendorsAddressTypes() {
    this.store.dispatch(vendorsActions.loadVendorAddressTypes())
  }

  selectVendor(vendorId: number) {
    this.store.dispatch(vendorsActions.loadVendorDetails({ vendorId }))
  }

  async loadVendorContacts(filters: DataFilters) {
    const vendorId = (await firstValueFrom(this.selectedVendorId$)) as number

    this.store.dispatch(
      vendorsActions.loadVendorContacts({ vendorId, filters })
    )
  }

  async loadVendorContactById(vendorId: number, filters: DataFilters) {
    this.store.dispatch(
      vendorsActions.loadVendorContacts({ vendorId, filters })
    )
  }

  async loadVendorAddresses(filters: DataFilters) {
    const vendorId = (await firstValueFrom(this.selectedVendorId$)) as number
    this.store.dispatch(
      vendorsActions.loadVendorAddresses({ vendorId, filters })
    )
  }

  startLoading(operation: AsyncOperations) {
    this.store.dispatch(
      vendorsActions.setLoading({
        operation,
        loading: true
      })
    )
  }
  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      vendorsActions.setLoading({
        operation,
        loading: false
      })
    )
  }

  addVendor(newVendor: VendorForm) {
    this.store.dispatch(vendorsActions.addVendor({ vendor: newVendor }))
  }

  updateVendor(vendor: VendorForm) {
    this.store.dispatch(vendorsActions.updateVendor({ vendor }))
  }

  updateVendorAccountNumbers(vendor: VendorForm) {
    this.store.dispatch(vendorsActions.updateVendorAccountNumbers({ vendor }))
  }

  async updateVendorContact(contact: VendorForm['contact']) {
    const vendorId = await firstValueFrom(this.selectedVendorId$)

    this.store.dispatch(
      vendorsActions.updateVendorContact({
        vendor: {
          contact,
          id: vendorId as number
        }
      })
    )
  }

  async updateVendorAddress(address: VendorForm['address']) {
    const vendorId = await firstValueFrom(this.selectedVendorId$)

    this.store.dispatch(
      vendorsActions.updateVendorAddress({
        vendor: {
          address,
          id: vendorId as number
        }
      })
    )
  }

  async addVendorContact(contact: VendorForm['contact']) {
    const vendorId = await firstValueFrom(this.selectedVendorId$)
    this.store.dispatch(
      vendorsActions.addVendorContact({
        vendor: { contact: contact, id: vendorId as number }
      })
    )
  }

  async addVendorAddress(address: VendorForm['address']) {
    const vendorId = await firstValueFrom(this.selectedVendorId$)
    this.store.dispatch(
      vendorsActions.addVendorAddress({
        vendor: { address, id: vendorId as number }
      })
    )
  }

  async deleteVendorContact(vendorContactId: number) {
    const vendorId = await firstValueFrom(this.selectedVendorId$)
    this.store.dispatch(
      vendorsActions.deleteVendorContact({
        vendorContactId,
        vendorId: vendorId as number
      })
    )
  }

  updateAddressActiveStatus(addressId: number, active: boolean) {
    this.store.dispatch(
      vendorsActions.updateVendorAddressActiveStatus({ addressId, active })
    )
  }

  async updateListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(vendorsActions.setListFilters({ filters: newFilters }))
  }

  async updateAddressListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.addressFilters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      vendorsActions.setAddessesListFilters({ addressesFilters: newFilters })
    )
  }

  async updateContactListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.contactFilters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      vendorsActions.setContactListFilters({ contactFilters: newFilters })
    )
  }

  resetAddressListFilters() {
    this.store.dispatch(vendorsActions.resetAddessesListFilters())
  }

  resetContactListFilters() {
    this.store.dispatch(vendorsActions.resetContactListFilters())
  }

  patchListFilters(filters: DataFilters) {
    this.store.dispatch(vendorsActions.patchListFilters({ filters }))
  }

  resetFilters() {
    this.store.dispatch(vendorsActions.resetListFilters())
  }
}
