import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  VENDORS_FEATURE_KEY,
  VendorsState,
  vendorsAdapter
} from './vendors.reducer'

export const selectVendorsState =
  createFeatureSelector<VendorsState>(VENDORS_FEATURE_KEY)

const { selectAll, selectEntities } = vendorsAdapter.getSelectors()

export const selectAllVendors = createSelector(
  selectVendorsState,
  (state: VendorsState) => selectAll(state)
)

export const selectAllVendorsAsEntity = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.entities
)

export const selectVendorsEntities = createSelector(
  selectVendorsState,
  (state: VendorsState) => selectEntities(state)
)

export const selectSelectedId = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.selectedId
)

export const selectTotalCount = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.totalCount
)

export const selectLoading = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.loading
)

export const selectEntity = createSelector(
  selectVendorsEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)

export const selecFilters = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.filters
)

export const selecVendorStatuses = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.statusTypes
)

export const selectAccountNumberStatuses = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.accountNumberStatuses
)

export const selectContactListFilters = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.contactFilters
)

export const selectAddressesListFilters = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.addressesFilters
)

export const selectAddressTypes = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.addressTypes
)
