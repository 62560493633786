import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  of,
  tap,
  finalize,
  withLatestFrom
} from 'rxjs'
import { vendorsActions } from './vendors.actions'
import { VendorsFacade } from './vendors.facade'
import { AsyncOperations } from '../domain/vendors-loading.model'
import { VendorsService } from '../infrastructure/vendors.service'
import { Vendor } from '../domain/vendor.model'
import { VendorAdapter } from '../adapter/VendorAdapter'
import { FromGetVendorsResponse } from '../adapter/FromGetVendorsResponse'
import { StatusType } from '../domain/status-type.model'
import { ToAddVendorRequest } from '../adapter/ToAddVendorRequest'
import {
  ADD_VENDOR_ADDRESS_DIALOG_ID,
  ADD_VENDOR_CONTACT_DIALOG_ID,
  ADD_VENDOR_DIALOG_ID,
  DELETE_VENDOR_CONTACT_DIALOG_ID,
  EDIT_VENDOR_ACCOUNT_NUMBERS_DIALOG_ID,
  EDIT_VENDOR_ADDRESS_DIALOG_ID,
  EDIT_VENDOR_CONTACT_DIALOG_ID
} from './vendors.constants'
import { FromGetVendorDetailsResponse } from '../adapter/FromGetVendorDetailsResponse'
import { FromGetVendorContactsResponse } from '../adapter/FromGetVendorContactsResponse'
import { FromGetVendorAddressResponse } from '../adapter/FromGetVendorAddressesResponse'
import { ToUpdateVendorRequest } from '../adapter/ToUpdateVendorRequest'
import { EDIT_VENDOR_DIALOG_ID } from './vendors.constants'
import { ToAddVendorContactRequest } from '../adapter/ToAddVendorContactRequest'
import { ToAddVendorAddressRequest } from '../adapter/ToAddVendorAddressRequest'
import { AddressType } from '../domain/address-type.model'
import { ToUpdateVendorContactRequest } from '../adapter/ToUpdateVendorContactRequest'
import { ToUpdateVendorAddressRequest } from '../adapter/ToUpdateVendorAddressRequest'

@Injectable({
  providedIn: 'root'
})
export class VendorsEffects {
  private actions$ = inject(Actions)
  private vendorsService = inject(VendorsService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private vendorsFacade = inject(VendorsFacade)

  private dialog = inject(Dialog)

  private vendorAdapter = new VendorAdapter()

  constructor() {
    this.vendorAdapter.set(FromGetVendorsResponse)
    this.vendorAdapter.set(FromGetVendorDetailsResponse)
    this.vendorAdapter.set(FromGetVendorContactsResponse)
    this.vendorAdapter.set(FromGetVendorAddressResponse)
    this.vendorAdapter.set(ToAddVendorRequest)
    this.vendorAdapter.set(ToUpdateVendorRequest)
    this.vendorAdapter.set(ToAddVendorContactRequest)
    this.vendorAdapter.set(ToAddVendorAddressRequest)
    this.vendorAdapter.set(ToUpdateVendorContactRequest)
    this.vendorAdapter.set(ToUpdateVendorAddressRequest)
  }

  loadVendors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.loadVendors),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.getAll)),
      exhaustMap(({ filters }) =>
        this.vendorsService.getVendors(filters).pipe(
          map(response => ({
            vendors: this.vendorAdapter.convert(
              FromGetVendorsResponse,
              response
            ),
            totalCount: response['@odata.count']
          })),

          switchMap(response => [
            vendorsActions.loadVendorsSuccess({
              vendors: response.vendors
            }),
            vendorsActions.setTotalCount({ count: response.totalCount })
          ]),
          catchError(error => of(vendorsActions.loadVendorsFail({ error }))),
          finalize(() => this.vendorsFacade.endLoading(AsyncOperations.getAll))
        )
      )
    )
  )

  loadVendorsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.loadVendorsFail),
        tap(error => {
          console.log(error)
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load vendors."
          })
        })
      ),
    { dispatch: false }
  )

  loadVendorStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.loadVendorsStatuses),
      switchMap(() => this.vendorsService.getVendorStatuses()),
      map(response => response.value),
      map(response =>
        response.map(
          status =>
            <StatusType>{
              id: status.VendorStatusTypeId,
              description: status.StatusName
            }
        )
      ),
      map(statusTypes =>
        vendorsActions.loadVendorsStatusesSuccess({ statusTypes })
      )
    )
  )

  loadVendorAddressTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.loadVendorAddressTypes),
      switchMap(() => this.vendorsService.getVendorAddressTypes()),
      map(response =>
        response.map(
          type =>
            <AddressType>{
              id: type.vendorLocationTypeId,
              description: type.description
            }
        )
      ),
      map(addressTypes =>
        vendorsActions.loadVendorAddressTypesSuccess({ addressTypes })
      )
    )
  )

  //#region Add Vendor
  addVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.addVendor),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.add)),
      map(({ vendor }) =>
        this.vendorAdapter.convert(ToAddVendorRequest, vendor)
      ),
      exhaustMap(request =>
        this.vendorsService.addVendor(request).pipe(
          map(() => vendorsActions.addVendorSuccess()),
          catchError(error => of(vendorsActions.addVendorFail({ error }))),
          finalize(() => this.vendorsFacade.endLoading(AsyncOperations.add))
        )
      )
    )
  )

  addVendorOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.addVendorSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(ADD_VENDOR_DIALOG_ID)?.close()),
      withLatestFrom(this.vendorsFacade.filters$),
      switchMap(([, filters]) => of(vendorsActions.loadVendors({ filters })))
    )
  )

  addVendorOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.addVendorFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to create vendor."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region load details

  loadVendorDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.loadVendorDetails),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.getDetails)),
      exhaustMap(({ vendorId }) =>
        this.vendorsService.getVendorDetails(vendorId).pipe(
          map(details =>
            this.vendorAdapter.convert(FromGetVendorDetailsResponse, details)
          ),
          map(vendor => ({ ...vendor, id: vendorId })),
          map(vendor => vendorsActions.loadVendorDetailsSuccess({ vendor })),
          tap(() => this.vendorsFacade.endLoading(AsyncOperations.getDetails))
        )
      )
    )
  )

  loadVendorContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.loadVendorContacts),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.getContacts)),
      exhaustMap(({ vendorId, filters }) =>
        this.vendorsService.getVendorContacts(vendorId, filters).pipe(
          map(contacts => ({
            contactsCount: contacts['@odata.count'],
            contacts: this.vendorAdapter.convert(
              FromGetVendorContactsResponse,
              contacts
            ).contacts,
            id: vendorId
          })),

          map(vendor => vendor as Vendor),
          map(vendor =>
            vendorsActions.loadVendorContactsSuccess({
              vendorWithContacts: vendor
            })
          ),
          tap(() => this.vendorsFacade.endLoading(AsyncOperations.getContacts))
        )
      )
    )
  )

  loadVendorAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.loadVendorAddresses),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.getAddresses)),
      exhaustMap(({ vendorId, filters }) =>
        this.vendorsService.getVendorAddresses(vendorId, filters).pipe(
          map(addresses => ({
            addressesCount: addresses['@odata.count'],
            addresses: this.vendorAdapter.convert(
              FromGetVendorAddressResponse,
              addresses
            ).addresses,
            id: vendorId
          })),

          map(vendor => vendor as Vendor),
          map(vendor =>
            vendorsActions.loadVendorAddressesSuccess({
              vendorWithAddresses: vendor
            })
          ),
          tap(() => this.vendorsFacade.endLoading(AsyncOperations.getAddresses))
        )
      )
    )
  )
  //#endregion

  //#region Update Vendor

  updateVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendor),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.updateVendor)),
      map(({ vendor }) => ({
        request: this.vendorAdapter.convert(ToUpdateVendorRequest, vendor),
        vendor
      })),
      exhaustMap(({ request, vendor }) =>
        this.vendorsService.updateVendor(request).pipe(
          map(() => vendorsActions.updateVendorSuccess({ vendor })),
          catchError(error => of(vendorsActions.updateVendorFail({ error }))),
          tap(() => this.vendorsFacade.endLoading(AsyncOperations.updateVendor))
        )
      )
    )
  )

  updateVendorOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(EDIT_VENDOR_DIALOG_ID)?.close()),
      switchMap(({ vendor }) =>
        of(vendorsActions.loadVendorDetails({ vendorId: vendor.id }))
      )
    )
  )

  updateVendorOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.updateVendorFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to update vendor."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Update Vendor Account numbers

  updateVendorAccountNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorAccountNumbers),
      tap(() =>
        this.vendorsFacade.startLoading(
          AsyncOperations.updateVendorAccountNumbers
        )
      ),
      map(({ vendor }) => ({
        request: this.vendorAdapter.convert(ToUpdateVendorRequest, vendor),
        vendor
      })),
      exhaustMap(({ request, vendor }) =>
        this.vendorsService.updateVendor(request).pipe(
          map(() =>
            vendorsActions.updateVendorAccountNumbersSuccess({ vendor })
          ),
          catchError(error =>
            of(vendorsActions.updateVendorAccountNumbersFail({ error }))
          ),
          tap(() =>
            this.vendorsFacade.endLoading(
              AsyncOperations.updateVendorAccountNumbers
            )
          )
        )
      )
    )
  )

  updateVendorAccountNumbersOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorAccountNumbersSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor account numbers has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(
        () =>
          this.dialog
            .getDialogById(EDIT_VENDOR_ACCOUNT_NUMBERS_DIALOG_ID)
            ?.close()
      ),
      switchMap(({ vendor }) =>
        of(vendorsActions.loadVendorDetails({ vendorId: vendor.id }))
      )
    )
  )

  updateVendorAccountNumbersOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.updateVendorAccountNumbersFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to update vendor account numbers."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region add vendor contact

  addVendorContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.addVendorContact),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.addContact)),
      map(({ vendor }) => ({
        request: this.vendorAdapter.convert(ToAddVendorContactRequest, vendor),
        vendor
      })),
      exhaustMap(({ request, vendor }) =>
        this.vendorsService.addVendorContact(request).pipe(
          map(() => vendorsActions.addVendorContactSuccess({ vendor })),
          catchError(error =>
            of(vendorsActions.addVendorContactFail({ error }))
          ),
          tap(() => this.vendorsFacade.endLoading(AsyncOperations.addContact))
        )
      )
    )
  )

  addVendorContactOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.addVendorContactSuccess),
      withLatestFrom(this.vendorsFacade.contactFilters$),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor contact has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(
        () => this.dialog.getDialogById(ADD_VENDOR_CONTACT_DIALOG_ID)?.close()
      ),
      switchMap(([{ vendor }, filters]) =>
        of(vendorsActions.loadVendorContacts({ filters, vendorId: vendor.id }))
      )
    )
  )

  addVendorContactOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.addVendorContactFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to add vendor contact."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region add vendor address

  addVendorAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.addVendorAddress),
      tap(() => this.vendorsFacade.startLoading(AsyncOperations.addAddress)),
      map(({ vendor }) => ({
        request: this.vendorAdapter.convert(ToAddVendorAddressRequest, vendor),
        vendor
      })),
      exhaustMap(({ request, vendor }) =>
        this.vendorsService.addVendorAddress(request).pipe(
          map(() => vendorsActions.addVendorAddressSuccess({ vendor })),
          catchError(httpError =>
            of(vendorsActions.addVendorAddressFail({ error: httpError.error }))
          ),
          tap(() => this.vendorsFacade.endLoading(AsyncOperations.addAddress))
        )
      )
    )
  )

  addVendorAddressOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.addVendorAddressSuccess),
      withLatestFrom(this.vendorsFacade.addressFilters$),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor address has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(
        () => this.dialog.getDialogById(ADD_VENDOR_ADDRESS_DIALOG_ID)?.close()
      ),
      switchMap(([{ vendor }, filters]) =>
        of(vendorsActions.loadVendorAddresses({ filters, vendorId: vendor.id }))
      )
    )
  )

  addVendorAddressOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.addVendorAddressFail),
        map(({ error }) =>
          error?.includes('Primary already exists')
            ? 'A default address already exists for this vendor.'
            : "There's an error trying to add the address."
        ),
        tap(label => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Update Vendor Contact

  updateVendorContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorContact),
      tap(() =>
        this.vendorsFacade.startLoading(AsyncOperations.updateVendorContact)
      ),
      map(({ vendor }) => ({
        request: this.vendorAdapter.convert(
          ToUpdateVendorContactRequest,
          vendor
        ),
        vendor
      })),
      exhaustMap(({ request, vendor }) =>
        this.vendorsService.updateVendorContact(request).pipe(
          map(() => vendorsActions.updateVendorContactSuccess({ vendor })),
          catchError(httpError =>
            of(
              vendorsActions.updateVendorContactFail({
                error: httpError.error
              })
            )
          ),
          tap(() =>
            this.vendorsFacade.endLoading(AsyncOperations.updateVendorContact)
          )
        )
      )
    )
  )

  updateVendorContactOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorContactSuccess),
      withLatestFrom(this.vendorsFacade.contactFilters$),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor contact has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(
        () => this.dialog.getDialogById(EDIT_VENDOR_CONTACT_DIALOG_ID)?.close()
      ),
      switchMap(([{ vendor }, filters]) =>
        of(
          vendorsActions.loadVendorContacts({
            vendorId: vendor.id,
            filters
          })
        )
      )
    )
  )

  updateVendorContactOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.updateVendorContactFail),
        map(({ error }) =>
          error.includes('Primary already exists')
            ? 'A default contact already exists for this vendor.'
            : "There's an error trying to update vendor contact."
        ),
        tap(label => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region delete Vendor Contact

  deleteVendorContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.deleteVendorContact),
      tap(() =>
        this.vendorsFacade.startLoading(AsyncOperations.deleteVendorContact)
      ),
      exhaustMap(({ vendorContactId, vendorId }) =>
        this.vendorsService.deleteVendorContact(vendorContactId).pipe(
          map(() => vendorsActions.deleteVendorContactSuccess({ vendorId })),
          tap(() =>
            this.vendorsFacade.endLoading(AsyncOperations.updateVendorContact)
          )
        )
      )
    )
  )

  deleteVendorContactOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.deleteVendorContactSuccess),
      withLatestFrom(this.vendorsFacade.contactFilters$),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor contact has been deleted successfully.`,
          alertType: 'success'
        })
      }),
      tap(
        () =>
          this.dialog.getDialogById(DELETE_VENDOR_CONTACT_DIALOG_ID)?.close()
      ),
      switchMap(([{ vendorId }, filters]) =>
        of(
          vendorsActions.loadVendorContacts({
            vendorId,
            filters
          })
        )
      )
    )
  )
  //#endregion

  //#region Update Vendor Address

  updateVendorAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorAddress),
      tap(() =>
        this.vendorsFacade.startLoading(AsyncOperations.updateVendorAddress)
      ),
      map(({ vendor }) => ({
        request: this.vendorAdapter.convert(
          ToUpdateVendorAddressRequest,
          vendor
        ),
        vendor
      })),
      exhaustMap(({ request, vendor }) =>
        this.vendorsService.updateVendorAddress(request).pipe(
          map(() => vendorsActions.updateVendorAddressSuccess({ vendor })),
          catchError(httpError =>
            of(
              vendorsActions.updateVendorAddressFail({ error: httpError.error })
            )
          ),
          tap(() =>
            this.vendorsFacade.endLoading(AsyncOperations.updateVendorAddress)
          )
        )
      )
    )
  )

  updateVendorAddressOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorAddressSuccess),
      withLatestFrom(this.vendorsFacade.addressFilters$),

      tap(() => {
        this.alertsFacade.addAlert({
          label: `Vendor address has been updateed successfully.`,
          alertType: 'success'
        })
      }),
      tap(
        () => this.dialog.getDialogById(EDIT_VENDOR_ADDRESS_DIALOG_ID)?.close()
      ),
      switchMap(([{ vendor }, filters]) =>
        of(vendorsActions.loadVendorAddresses({ filters, vendorId: vendor.id }))
      )
    )
  )

  updateVendorAddressOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.updateVendorAddressFail),
        map(({ error }) =>
          error?.includes('Primary already exists')
            ? 'A default address already exists for this vendor.'
            : "There's an error trying to update the address."
        ),
        tap(label => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Update Vendor Address Active Status

  updateVendorAddressActiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsActions.updateVendorAddressActiveStatus),
      withLatestFrom(this.vendorsFacade.selectedVendor$),
      exhaustMap(([payload, vendor]) =>
        this.vendorsService
          .updateVendorAddressActiveStatus(payload.addressId, payload.active)
          .pipe(
            map(() => {
              const isActive = (address: Vendor['addresses'][number]) =>
                address.id === payload.addressId
                  ? payload.active
                  : address.isActive
              return vendorsActions.updateVendorAddressActiveStatusSuccess({
                vendorWithUpdatedAddress: {
                  ...(vendor as Vendor),
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  addresses: vendor!.addresses.map(address => ({
                    ...address,
                    isActive: isActive(address)
                  }))
                }
              })
            }),
            catchError(error =>
              of(
                vendorsActions.updateVendorAddressActiveStatusFail({
                  error
                })
              )
            )
          )
      )
    )
  )

  updateVendorAddressActiveStatusOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsActions.updateVendorAddressActiveStatusFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label:
              "There's an error trying to update vendor address active status."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion
}
