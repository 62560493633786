export enum VendorStatuses {
  Active = 'Active',
  AwaitingApproval = 'Awaiting Approval',
  Inactive = 'Inactive'
}
export type Vendor = {
  id: number
  withDetails: boolean
  uuid: string

  name: string
  status: VendorStatuses
  statusId: number
  dotNumber: string
  mcNumber: string
  scac: string
  address?: Partial<{
    address1: string
    address2: string
    country: string
    city: string
    state: string
    zip: string
    zip4: string
  }>
  addressesCount: number
  addresses: {
    name: string
    address1: string
    address2: string
    country: string
    city: string
    state: string
    zip: string
    zip4: string
    isPrimary: boolean
    isActive: boolean
    typeId: number
    typeDescription: string
    id: number
  }[]
  contactsCount: number
  contacts: Partial<{
    name: string
    email: string
    departmentId: string
    phone: string
    isPrimary: boolean
    id: number
  }>[]

  accountNumbers: {
    isPrimary: boolean
    accountNumber: string
    id: number
  }[]
  paymentMethod: string
  currencyId: number
  currencyDescription: string

  billingTermId: number
  billingTermDescription: string
  externalId: string
}
