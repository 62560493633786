export enum AsyncOperations {
  getAll = 'getAll',
  add = 'add',
  getDetails = 'getDetails',
  getContacts = 'getContacts',
  getAddresses = 'getAddresses',
  updateVendor = 'updateVendor',
  updateVendorContact = 'updateVendorContact',
  updateVendorAddress = 'updateVendorAddress',
  updateVendorAccountNumbers = 'updateVendorAccountNumbers',
  addAddress = 'addAddress',
  addContact = 'addContact',
  deleteVendorContact = 'deleteVendorContact'
}

export type VendorsLoading = {
  [key in AsyncOperations]: boolean
}
