import { IStrategy } from '@navix/utils/adapter'
import { VendorForm } from '../domain/vendor-form.model'
import { UpdateVendorRequest } from '../domain/update-vendor.request'

export class ToUpdateVendorRequest
  implements IStrategy<VendorForm, UpdateVendorRequest>
{
  convert(vendor: VendorForm): UpdateVendorRequest {
    return {
      vendorId: vendor.id,
      accountNumbers: vendor.accountNumbers.map(accountNumber => ({
        accountNumber: accountNumber.name,
        isPrimary: accountNumber.isPrimary as boolean,
        vendorAccountNumberId: accountNumber.id
      })),
      billingTermId: vendor.details.billingTermId,
      description: vendor.details.name,
      dotNumber: vendor.details.dotNumber,
      mcNumber: vendor.details.mcNumber,
      scacCode: vendor.details.scac,
      vendorStatusTypeId: vendor.details.statusId,
      currencyId: vendor.details.currencyId as number,
      externalId: vendor.details.externalId
    }
  }
}
