import { IStrategy } from '@navix/utils/adapter'
import { AddVendorContactRequest } from '../domain/add-vendor-contact.request'
import { VendorForm } from '../domain/vendor-form.model'

export class ToAddVendorContactRequest
  implements IStrategy<VendorForm, AddVendorContactRequest>
{
  convert(vendor: VendorForm): AddVendorContactRequest {
    return {
      contactName: vendor.contact.name,
      department: vendor.contact.department,
      contactPhone: vendor.contact.phone,
      contactEmail: vendor.contact.email,
      isPrimary: false,
      vendorId: vendor.id
    }
  }
}
