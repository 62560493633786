import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Vendor } from '../domain/vendor.model'
import { AsyncOperations } from '../domain/vendors-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { StatusType } from '../domain/status-type.model'
import { VendorForm } from '../domain/vendor-form.model'
import { AddressType } from '../domain/address-type.model'

export const vendorsActions = createActionGroup({
  source: 'Vendors',
  events: {
    //#region General vendors
    'load vendors': props<{ filters: DataFilters }>(),
    'load vendors success': props<{ vendors: Vendor[] }>(),
    'load vendors fail': props<{ error: unknown }>(),

    'set total count': props<{ count: number }>(),
    'set list filters': props<{ filters: DataFilters }>(),
    'patch list filters': props<{ filters: DataFilters }>(),
    'reset list filters': emptyProps(),

    'load vendors statuses': emptyProps(),
    'load vendors statuses success': props<{ statusTypes: StatusType[] }>(),
    'load vendors statuses fail': props<{ error: unknown }>(),

    'load vendor details': props<{ vendorId: number }>(),
    'load vendor details success': props<{ vendor: Vendor }>(),

    'add vendor': props<{ vendor: VendorForm }>(),
    'add vendor success': emptyProps(),
    'add vendor fail': props<{ error: string }>(),

    'update vendor': props<{
      vendor: Pick<VendorForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update vendor success': props<{
      vendor: Pick<VendorForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update vendor fail': props<{ error: string }>(),

    'update vendor account numbers': props<{
      vendor: Pick<VendorForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update vendor account numbers success': props<{
      vendor: Pick<VendorForm, 'accountNumbers' | 'id' | 'details'>
    }>(),
    'update vendor account numbers fail': props<{ error: string }>(),

    'set loading': props<{ operation: AsyncOperations; loading: boolean }>(),
    //#endregion

    //#region Vendor Address
    'set addesses list filters': props<{ addressesFilters: DataFilters }>(),
    'reset addesses list filters': emptyProps(),

    'update vendor address active status': props<{
      addressId: number
      active: boolean
    }>(),
    'update vendor address active status success': props<{
      vendorWithUpdatedAddress: Vendor
    }>(),
    'update vendor address active status fail': props<{ error: unknown }>(),

    'load vendor address types': emptyProps(),
    'load vendor address types success': props<{
      addressTypes: AddressType[]
    }>(),
    'load vendor address types fail': props<{ error: unknown }>(),

    'load vendor addresses': props<{
      vendorId: number
      filters: DataFilters
    }>(),
    'load vendor addresses success': props<{ vendorWithAddresses: Vendor }>(),

    'add vendor address': props<{
      vendor: Pick<VendorForm, 'address' | 'id'>
    }>(),
    'add vendor address success': props<{
      vendor: Pick<VendorForm, 'address' | 'id'>
    }>(),
    'add vendor address fail': props<{ error: string }>(),

    'update vendor address': props<{
      vendor: Pick<VendorForm, 'address' | 'id'>
    }>(),
    'update vendor address success': props<{
      vendor: Pick<VendorForm, 'address' | 'id'>
    }>(),
    'update vendor address fail': props<{ error: string }>(),
    //#endregion

    //#region Vendor Contact
    'set contact list filters': props<{ contactFilters: DataFilters }>(),
    'reset contact list filters': emptyProps(),

    'add vendor contact': props<{
      vendor: Pick<VendorForm, 'contact' | 'id'>
    }>(),
    'add vendor contact success': props<{
      vendor: Pick<VendorForm, 'contact' | 'id'>
    }>(),
    'add vendor contact fail': props<{ error: string }>(),

    'update vendor contact': props<{
      vendor: Pick<VendorForm, 'contact' | 'id'>
    }>(),
    'update vendor contact success': props<{
      vendor: Pick<VendorForm, 'contact' | 'id'>
    }>(),
    'update vendor contact fail': props<{ error: string }>(),

    'load vendor contacts': props<{ vendorId: number; filters: DataFilters }>(),
    'load vendor contacts success': props<{ vendorWithContacts: Vendor }>(),

    'delete vendor contact': props<{
      vendorContactId: number
      vendorId: number
    }>(),
    'delete vendor contact success': props<{ vendorId: number }>()
    //#endregion
  }
})
